import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27bfa0f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["viewBox"]
const _hoisted_2 = ["xlink:href"]
const _hoisted_3 = ["x", "y", "onMouseover"]
const _hoisted_4 = { class: "scale" }
const _hoisted_5 = ["onClick", "d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tippy = _resolveDirective("tippy")!

  return ($setup.props.map)
    ? (_openBlock(), _createElementBlock("svg", {
        key: 0,
        class: "svg-map-scale",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        viewBox: `0 0 ${$setup.props.map.size.width} ${$setup.props.map.size.height}`,
        style: _normalizeStyle({ transform: 'scale(' + $setup.props.zoom + ')' })
      }, [
        _createElementVNode("image", {
          "xlink:href": $setup.props.map.mapImage.url
        }, null, 8, _hoisted_2),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.stages, (stage) => {
          return (_openBlock(), _createElementBlock("svg", {
            class: "item-wr",
            xmlns: "http://www.w3.org/2000/svg",
            "xmlns:xlink": "http://www.w3.org/1999/xlink",
            version: "1.1",
            x: stage.binding.x,
            y: stage.binding.y,
            onMouseover: ($event: any) => ($setup.onStageHover(stage?.stageId?._id)),
            onMouseleave: $setup.onStageLeave
          }, [
            _createElementVNode("g", _hoisted_4, [
              _withDirectives(_createElementVNode("path", {
                class: _normalizeClass(["item-path", $setup.props.getItemPathClasses(stage)]),
                onClick: ($event: any) => ($setup.onStage(stage)),
                d: stage.path
              }, null, 10, _hoisted_5), [
                [_directive_tippy, { content: $props.getTooltipText(stage), allowHTML: true, theme: 'map-tippy', appendtTo: 'element', arrow: false }]
              ])
            ])
          ], 40, _hoisted_3))
        }), 256))
      ], 12, _hoisted_1))
    : _createCommentVNode("", true)
}