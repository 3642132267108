
const pinchZoomHammer = (
  scale: number, callback: (deltaScale : number) => void
) : void => {

  let currentScale = 1;
  const smoothingFactor = 0.2;
  const targetScale = currentScale * scale;
  const smoothScale = currentScale + (targetScale - currentScale) * smoothingFactor;
  
  const minScale = 0.5;
  const maxScale = 2;
  const smoothedScale = Math.min(Math.max(smoothScale, minScale), maxScale);

  const deltaScale = smoothedScale - currentScale;
  currentScale = smoothedScale;

  callback(deltaScale);
}

export default pinchZoomHammer;