import {CustomerLotStatus, LotStatusMap, LotStatusObject, LotTab,} from "@/types/CustomerLotDetails";
import {EoiStatus} from "@/enums/EoiStatus";
import {LotStatus} from "@/enums/LotStatus";

export const lotTabs: LotTab[] = [
  { label: "ACTIVITY", value: "activity" },
  { label: "DOCUMENTS", value: "documents" },
  { label: "CONTRACT INFORMATION FORM", value: "eoi-details" },
  { label: "CONTRACT", value: "contracts" },
  { label: "CONTACT US", value: "estate-contacts" },
];

const customerEoiStatuses: [string, LotStatusObject][] = [
  [
    "PENDING",
    {
      label: "Submitted",
      value: "pending",
      background: "success-light",
      badgeLabel: "Submitted",
    },
  ],
  [
    "DRAFT",
    {
      label: "Submitted",
      value: "draft",
      background: "gray",
      badgeLabel: "Draft",
    },
  ],
  [
    "PENDING_FOR_BILLING",
    {
      label: "Submitted",
      value: "pending for billing",
      background: "gray",
      badgeLabel: "Pending For Billing",
    },
  ],
  [
    "APPROVED",
    {
      label: "Approved",
      value: "approved",
      background: "light-blue",
      badgeLabel: "Approved",
    },
  ],
  [
    "DECLINED",
    {
      label: "Declined",
      value: "declined",
      background: "warning-light",
      badgeLabel: "Declined",
    },
  ],
  [
    "TERMINATED",
    {
      label: "Submitted",
      value: "terminated",
      background: "gray-light",
      badgeLabel: "Terminated",
    },
  ],
  [
    "WAITING_FOR_APPROVED",
    {
      label: "Submitted",
      value: "waitingForApproved",
      background: "primary",
      badgeLabel: "Submitted",
    },
  ],
  [
    "WAITING_FOR_DECLINED",
    {
      label: "Submitted",
      value: "waitingForDeclined",
      background: "warning-light",
      badgeLabel: "EOI Declining",
    },
  ],
  [
    "TRANSACTION_ERROR",
    {
      label: "Submitted",
      value: "transaction_error",
      background: "warning",
      badgeLabel: "Transaction Error",
      badgeBackground: "warning",
    },
  ],
  [
    "WAITING_FOR_PENDING",
    {
      label: "Submitted",
      value: "waitingForPending",
      background: "blue",
      badgeLabel: "EOI Submitting",
      badgeBackground: "blue",
    },
  ],
];
const customerLotStatuses: [string, LotStatusObject][] = [
  [
    "CONTRACT_EXECUTED",
    {
      label: "Contract Executed",
      value: "Contract Executed",
      background: "orange-light",
      badgeLabel: "Contract Executed",
    },
  ],
  [
    "EOI",
    {
      label: "Contract Executed",
      value: "EOI",
      background: "",
      badgeLabel: "Approved",
    },
  ],
  [
    "UNCONDITIONAL",
    {
      label: "Finance Unconditional",
      value: "Unconditional",
      background: "primary",
      badgeLabel: "Finance Unconditional",
    },
  ],
  [
    "SETTLED",
    {
      label: "Settled",
      value: "Settled",
      background: "warning",
      badgeLabel: "Settled",
    },
  ],
  [
    "WITHDRAWN",
    {
      label: "Withdrawn",
      value: "withdrawn",
      background: "orange",
      badgeLabel: "Withdrawn",
    },
  ],
  [
    "REFUNDED",
    {
      label: "Deposit refunded",
      value: "refunded",
      background: "warning-light",
      badgeLabel: "Deposit refunded",
    },
  ],
  [
    "REFUND_DECLINED",
    {
      label: "Deposit not refunded",
      value: "refundDeclined",
      background: "warning-light",
      badgeLabel: "Deposit not refunded",
    },
  ],
];
export const customerStatuses = new Map([
  ...customerEoiStatuses,
  ...customerLotStatuses,
]);

export const eoiPendingStatuses = [
  customerStatuses.get("PENDING")?.value,
  customerStatuses.get("DRAFT")?.value,
  customerStatuses.get("PENDING_FOR_BILLING")?.value,
  customerStatuses.get("TERMINATED")?.value,
  customerStatuses.get("WAITING_FOR_APPROVED")?.value,
  customerStatuses.get("WAITING_FOR_DECLINED")?.value,
  customerStatuses.get("TRANSACTION_ERROR")?.value,
  customerStatuses.get("WAITING_FOR_PENDING")?.value,
];
const eoiApprovingStatuses = [
  customerStatuses.get("APPROVED")?.value,
  customerStatuses.get("DECLINED")?.value,
];
export const isEoiPendingStatus = (status: CustomerLotStatus): boolean =>
  eoiPendingStatuses.includes(status);
export const isEoiApprovingStatus = (status: CustomerLotStatus): boolean =>
  eoiApprovingStatuses.includes(status);

function getEoiPendingStatus(eoiStatus: CustomerLotStatus) {
  const statuses = Array.from(customerStatuses.values()) as LotStatusObject[];
  if (isEoiPendingStatus(eoiStatus)) {
    return getStatusByLabel(statuses, eoiStatus);
  } else {
    return customerStatuses.get("PENDING");
  }
}
function getFinishedStatus(eoiStatus: EoiStatus) {
  if (eoiStatus === customerStatuses.get("REFUND_DECLINED")?.value) {
    return customerStatuses.get("REFUND_DECLINED");
  }
  if (eoiStatus === customerStatuses.get("REFUNDED")?.value) {
    return customerStatuses.get("REFUNDED");
  }
  if (eoiStatus === EoiStatus.WITHDRAWN) {
    return {
      background: "gray",
      badgeLabel: "Refunded",
      label: "Refunded",
      value: "Settled",
    };
  }
  return customerStatuses.get("SETTLED");
}

export function dynamicStatuses(
  status: CustomerLotStatus,
  eoiStatus: CustomerLotStatus
): LotStatusObject[] {
  const s = customerStatuses as LotStatusMap;
  return [
    getEoiPendingStatus(eoiStatus),
    eoiStatus === s.get("DECLINED")?.value
      ? s.get("DECLINED")
      : s.get("APPROVED"),
    status === s.get("EOI")?.value ? s.get("EOI") : s.get("CONTRACT_EXECUTED"),
    eoiStatus === s.get("WITHDRAWN")?.value
      ? customerStatuses.get("WITHDRAWN")
      : s.get("UNCONDITIONAL"),
    getFinishedStatus(eoiStatus as EoiStatus),
  ] as LotStatusObject[];
}

const getStatusByLabel = (
  statuses: LotStatusObject[],
  status: CustomerLotStatus
): LotStatusObject | undefined =>
  statuses.find((item) => item.value === status);

export function getValueByStatus(
  status: CustomerLotStatus,
  field: keyof LotStatusObject
): string | undefined {
  const statuses = customerStatuses.values();
  return getStatusByLabel(Array.from(statuses) as LotStatusObject[], status)?.[
    field
  ];
}

export function getTabByUrl(path: string): LotTab | undefined {
  return lotTabs.find((tab) => path.includes(tab.value));
}

export function getLotStatus(
  eoiStatus: EoiStatus,
  lotStatus: LotStatus
): LotStatus | EoiStatus {
  if (eoiStatus === EoiStatus.APPROVED) {
    if (lotStatus === LotStatus.EOI) {
      return EoiStatus.APPROVED;
    }
    return lotStatus;
  }
  return eoiStatus;
}

const { PENDING_FOR_BILLING, DRAFT, PENDING, APPROVED, TERMINATED } = EoiStatus;

export function showingEoiDetailControls(
  eoiStatus: EoiStatus,
  lotStatus: LotStatus
): {
  complete(): boolean;
  delete(): boolean;
  withdraw(): boolean;
  decline(): boolean;
} {
  return {
    complete() {
      return [PENDING_FOR_BILLING, DRAFT].includes(eoiStatus as EoiStatus);
    },
    decline() {
      return [PENDING].includes(eoiStatus as EoiStatus);
    },
    delete() {
      return [PENDING_FOR_BILLING, DRAFT, TERMINATED].includes(
        eoiStatus as EoiStatus
      );
    },
    withdraw() {
      return (
        [APPROVED].includes(eoiStatus as EoiStatus) &&
        [
          LotStatus.EOI,
          LotStatus.CONTRACT_EXECUTED,
          LotStatus.UNCONDITIONAL,
        ].includes(lotStatus)
      );
    },
  };
}

export const eoiStatusesHavingBackground = [
  EoiStatus.PENDING,
  EoiStatus.APPROVED,
  EoiStatus.DECLINED,
  EoiStatus.WAITING_FOR_APPROVED,
  EoiStatus.WITHDRAWN,
  EoiStatus.REFUND_DECLINED,
  EoiStatus.REFUNDED,
  EoiStatus.WAITING_FOR_PENDING,
];
