import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/builder-icon.svg'
import _imports_1 from '@/assets/icons/chevron-right-gray.svg'


const _withScopeId = n => (_pushScopeId("data-v-5f491709"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-wr" }
const _hoisted_2 = { class: "lot-details-item" }
const _hoisted_3 = { class: "lot-details-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["lot-btn-link-container", { 'lot-btn-link-container-hover': $setup.props.hoverLotId === $setup.props.lot.lotId._id, expanded: $setup.props.builderView }]),
    onMouseover: $setup.onHover,
    onMouseleave: $setup.onMouseLeave,
    onClick: $setup.onClick
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", {
        class: _normalizeClass(["lot-btn-title lot", { 'title-hover': $setup.hover }])
      }, "Lot " + _toDisplayString($setup.props.lot.lotId.title), 3),
      ($props.lot.lotId.isBuildersEnabled)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "builder-info-wr",
            onMouseover: $setup.onBuilderHover,
            onMouseleave: $setup.onBuilderLeave
          }, [
            (!$setup.props.builderView)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  class: _normalizeClass(["builder-info", { 'builder-icon-hover': $setup.hover }]),
                  src: _imports_0,
                  ref: "builderIconRef"
                }, null, 2))
              : _createCommentVNode("", true)
          ], 32))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["badge", $setup.getLotCustomerMapStatusClass($setup.getLotStatus($props.lot.lotId.status === $setup.LotStatus.OFFLINE ? $props.lot.lotId?.offlineSale?.salePhase : $props.lot.lotId.status))])
      }, _toDisplayString($setup.getLotStatus($props.lot.lotId.status === $setup.LotStatus.OFFLINE? $props.lot.lotId?.offlineSale?.salePhase
: $props.lot.lotId.status)), 3)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["lot-details-wr", { spacing: $setup.props.builderView }])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["detail-header", { 'title-hover': $setup.hover, 'builder-view': $setup.props.builderView }])
        }, "Size", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["detail-data", { 'title-hover': $setup.hover, 'builder-view': $setup.props.builderView }])
        }, _toDisplayString($props.lot.lotId.size) + " sqm", 3)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["detail-header", { 'title-hover': $setup.hover, 'builder-view': $setup.props.builderView }])
        }, "Price", 2),
        _createElementVNode("div", {
          class: _normalizeClass(["detail-data", { 'title-hover': $setup.hover, 'builder-view': $setup.props.builderView }])
        }, _toDisplayString($setup.formatPrice($props.lot.lotId.price)), 3)
      ]),
      (!$setup.props.builderView)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: _normalizeClass(["lot-btn-chevron", { 'title-hover': $setup.hover }]),
            src: _imports_1
          }, null, 2))
        : _createCommentVNode("", true)
    ], 2),
    ($setup.hover && $setup.props.builderView)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "button default primary select",
          onClick: $setup.onClick
        }, "Select Lot"))
      : _createCommentVNode("", true)
  ], 34))
}