const defaultActiveClass = "opened";

export function heightAnimation(element: HTMLElement, activeClass= defaultActiveClass, resized=false) {
  element.style.height = element.scrollHeight + "px";
  if (resized) {
    return
  }
  element.classList.toggle(activeClass);
  element.style.height = element.classList.contains(activeClass)
    ? element.scrollHeight + "px"
    : "0";
}
export function removeHeightOfSiblings(elements: HTMLElement[], activeElementIndex: number, activeClass = defaultActiveClass) {
  elements
    .filter((item, index) => index !== activeElementIndex)
    .forEach((filteredItem) => {
      filteredItem.classList.remove(activeClass);
      filteredItem.style.height = "0";
    })
}

// use to define and animate hidden elements
// styles example:
//               .html-element {
//                 height: 0;
//                 transition: height 0.3s;
//                 overflow: hidden;
//                }
//               .html-element.opened {
//                 height: auto;
//                }
